import React from 'react'
import { css } from '@emotion/react'
import footerGraphic from "../images/footer-vector.svg"
import discreteFooterGraphic from "../images/discrete-footer-vector.svg"
import GitHubIcon from '@mui/icons-material/GitHub';
//import LinkIcon from '@mui/icons-material/Link';
//import { StaticQuery, graphql } from 'gatsby'
import { IconButton, Link } from "gatsby-theme-material-ui";
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
//import CookieIcon from '@mui/icons-material/Cookie';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

const Footer = ({ discrete = false, }) => (
  <div css={css`position: absolute; bottom: 0; width: 100%;`}>
    <img
      alt=""
      src={discrete ? discreteFooterGraphic : footerGraphic}
      css={css`
        width:100%; 
        height: ${discrete ? "10rem" : "22rem"}; 
        position: absolute; 
        left: 0; 
        bottom: 0; 
        z-index: -1;
      `}
    />
    <div>
      <div css={css`
        width:100%; 
        text-align: center; 
        position: absolute; 
        bottom: ${discrete ? "3rem" : "1rem"}; 
        color: ${discrete ? "#555454" : "white"}; 
        font-size: 1rem;
      `}
      >
        <p css={css`margin-bottom: 0rem;color: ${discrete ? "#555454" : "white"}`}>Copyright ©{new Date().getFullYear()} TechLabs</p>

        <address css={css`margin-bottom: 1rem`}>
          <a href="https://goo.gl/maps/cySyoWeFynrixuCZA"
            css={css`
            &:visited, &:hover, &:active, &:link { 
              text-decoration: none; 
              color: ${discrete ? "#555454" : "white"}
              `}
          >
            Jan-Magnus Janssonin aukio 1<br />
            00580 Helsinki
          </a>
        </address>

        {!discrete &&
          <>
            {/* <IconButton component={Link} to="https://www.arcada.fi/en/research/strategic-focus" aria-label='Arcada Research Strategic Focus'>
              <LinkIcon color='secondary' />
            </IconButton> */}
            <Tooltip title={"Arcada UAS Github"} placement="top" >
            <IconButton component={Link} to="https://github.com/arcada-uas" aria-label='Arcada UAS Github'>
              <GitHubIcon color='secondary' />
            </IconButton>
            </Tooltip>
            <Tooltip title={"Privacy policy"} placement="top" >
              <IconButton to="/privacy-policy" aria-label='Privacy Policy'>
                <FingerprintIcon color='secondary' />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Accessibility"} placement="top" >
              <IconButton to="/accessibility" aria-label='Accessibility'>
                <AccessibilityNewIcon color='secondary' />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Search"} placement="top" >
              <IconButton to="/search" color='secondary' aria-label="search"  >
                <SearchIcon color='secondary' />
              </IconButton>
            </Tooltip>

          </>
        }

        {/* An idea to include some build info on the staging site only,
        However, including it in the footer with a staticQuery 
        results in a rebuild of every page during the gatsby build  */}

        {/* {process.env.GATSBY_IS_PREVIEW === 'true' &&
          <div css={css`background-color: black; position: fixed; bottom: 2rem; left: 2rem; padding: 1rem; text-align: left;`}>
            <div>Staging</div>
            <StaticQuery
              query={graphql`
                query MetaQuery {
                  siteBuildMetadata {
                    buildTime
                  }
                }
              `}
              render={data => (
                <div css={css`font-size: 0.6rem;`}>BUILD TIME: <br/> {data?.siteBuildMetadata?.buildTime}</div>
              )}
            />
          </div>
        } */}
      </div>
    </div>
  </div>
)

export default Footer

